import React, { useContext, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { ThemeContext } from 'styled-components';
import PuffLoader from 'react-spinners/PuffLoader';
import Button from '../../components/ui/button';
import { Col, Row } from '../../components/ui/Wrapper';
import SimpleHeader from '../Layout/SimpleHeader';
import { Actions, Card, CardTitle, Container, Content, LoadingContainer, Title } from './styles';
import headImage from '../../data/images/site-selection/procuram-cabecas.png';
import useCookies from '../../hooks/useCookies';

const SiteSelection = () => {
  const theme = useContext(ThemeContext);
  const { getCookie, setCookie } = useCookies();
  const [isRedirecting, setIsRedirecting] = useState(true);

  useEffect(() => {
    const siteType = getCookie('site-type');

    if (siteType) {
      navigate(`/${siteType}`);
    } else {
      setIsRedirecting(false);
    }
  }, [getCookie]);

  const handleSelectType = type => {
    navigate(`/${type}`);
    setCookie(`site-type`, type, { expires: 5 });
  };

  return isRedirecting ? (
    <LoadingContainer>
      <PuffLoader color={theme.colors.primary} size={60} />
    </LoadingContainer>
  ) : (
    <Container>
      <SimpleHeader />
      <Content>
        <Row alignitems="center">
          <Col lg={7} md={12}>
            <img src={headImage} alt="Procuram-se cabeças" />
          </Col>
          <Col lg={5} md={12}>
            <Title>Encontramos?</Title>
            <Card>
              <CardTitle>Comece agora:</CardTitle>
              <Actions>
                <Button
                  skin="secondary"
                  hover={{ bgColor: 'secondaryDark' }}
                  mb="14px"
                  onClick={() => handleSelectType('online')}
                >
                  Cursos on-line
                </Button>
                <Button
                  skin="secondary"
                  hover={{ bgColor: 'secondaryDark' }}
                  onClick={() => handleSelectType('presencial')}
                >
                  Cursos presenciais
                </Button>
              </Actions>
            </Card>
          </Col>
        </Row>
      </Content>
    </Container>
  );
};

export default SiteSelection;
