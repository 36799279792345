/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import SEO from '../components/seo';
import SiteSelection from '../containers/site-selection';

const IndexPage = ({ location }) => (
  <>
    <SEO title="Cursos Preparatórios para Concursos Públicos e Militares - Garra" location={location} />
    <SiteSelection />
  </>
);

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default IndexPage;
