import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  background-image: ${({ theme }) => `linear-gradient(${theme.colors.primary}, ${theme.colors.primaryLight})`};
`;

export const LoadingContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Card = styled.div`
  padding: ${({ theme }) => theme.spacings.lg};
  background-color: ${({ theme }) => theme.colors.primaryDark};
  border-radius: 8px;
`;

export const Content = styled.div`
  padding: ${({ theme }) => theme.spacings.lg};

  @media (min-width: 768px) {
    padding: 80px;
  }
`;

export const Title = styled.h5`
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: ${({ theme }) => theme.spacings.md};
`;

export const CardTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.typography.lg};
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: ${({ theme }) => theme.spacings.md};
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
`;
